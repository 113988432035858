<script lang="ts" setup>
import { ref, watch, PropType, computed, onBeforeMount, onBeforeUnmount } from 'vue';
import {
  makeFormModel,
  getChildModel,
  batchSetState,
  submitForm,
  makeValidation,
} from 'ah-common-lib/src/form/helpers';
import { TimeFrames } from 'ah-common-lib/src/constants/timeframes';
import { Subject, empty, Subscription, of } from 'rxjs';
import { debounceTime, switchMap, filter, tap, catchError } from 'rxjs/operators';
import { financialAmountField } from 'ah-common-lib/src/form/models/commonFields';
import { requiredIfStateValue } from 'ah-common-lib/src/form/validators';
import {
  AuthorityType,
  HedgingInstruments,
  LimitsErrorCodes,
  formatHedgingInstrument,
  FeatureFlag,
  FeatureFlagTreatment,
} from 'ah-api-gateways';
import isEqual from 'lodash/isEqual';
import { TimeFrameDate, DrawdownDate } from '../../models/date';
import { TradeDetails } from '../../models/trade';
import { useAhTradesState } from '../..';
import { Beneficiary, Wallet, AmountType } from 'ah-api-gateways';
import { FormEvent, FormModel } from 'ah-common-lib/src/form/interfaces';
import FieldWalletInfoOrErrors from 'ah-wallets/src/components/FieldWalletInfoOrErrors.vue';
import { QuotePriceRequest, pricingRequest } from '../../requests/pricingRequest';
import TradePriceExchangeRate from '../info/TradePriceExchangeRate.vue';
import { getPhoneNumber } from 'ah-common-lib/src/helpers/calls';
import ClientRateAndMarginForm from './ClientRateAndMarginForm.vue';
import { ApiError } from 'ah-requests';
import { getState } from 'ah-common-lib/src/form/helpers';
import useVuelidate from '@vuelidate/core';
import CurrencySelectInput from '../currency/CurrencySelectInput.vue';
import { pricingEngineError, PricingEngineErrorOptions } from './pricingEngineChecks';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

const props = defineProps({
  timeFrame: {
    type: Object as PropType<TimeFrameDate>,
    required: true,
  },

  /**
   * Trade details object
   *
   * synchronizable via `update:tradeDetails` or .sync modifier
   */
  tradeDetails: {
    type: Object as PropType<TradeDetails | null>,
    default: () => null,
  },

  /**
   * Trade price request and response (as received by getting/refreshing  the trade)
   *
   * synchronizable via `update:tradePrice` or .sync modifier
   */
  tradePrice: {
    type: Object as PropType<QuotePriceRequest | null>,
    default: () => null,
  },

  /**
   * DrawdownDate object
   */
  drawdownDate: {
    type: Object as PropType<DrawdownDate | null>,
    default: () => null,
  },

  /**
   * Possible beneficiary target of the trade
   * Will affect UX and options available in child components
   */
  beneficiary: {
    type: Object as PropType<Beneficiary>,
  },

  /**
   * Possible array of allowed buy currencies
   *
   * If set, user will only be able to choose from the currencies listed as a buy currency
   */
  allowedBuyCurrencies: {
    type: Array as PropType<string[]>,
  },

  /**
   * Possible array of allowed sell currencies
   *
   * If set, user will only be able to choose from the currencies listed as a sell currency
   */
  allowedSellCurrencies: {
    type: Array as PropType<string[]>,
  },
  /**
   * Whether to verify limits
   *
   * If true:
   * - client will verify limits
   */
  verifyLimits: {
    type: [String, Boolean],
    default: false,
  },
});

const emit = defineEmits({
  'update:tradePrice': (_payload: QuotePriceRequest | null) => true,
  'update:walletBalanceUsageRequired': (_payload: boolean) => true,
  'update:loadingPrices': (_payload: boolean) => true,
  'update:tradeDetails': (_payload: TradeDetails | null) => true,
});

const requestManager = useRequestManager({
  exposeToParent: true,
}).manager;

const onBehalfOfClient = useOnBehalfOf();

const clientRateAndMarginForm = ref<InstanceType<typeof ClientRateAndMarginForm>>();

const ahTradesState = useAhTradesState();

const amountForm = ref<FormModel>(
  makeFormModel({
    name: 'amountForm',
    title: '',
    fieldType: 'form',
    fields: [
      financialAmountField('buyAmount', '', {
        hideErrorMessages: true,
        errorMessages: { required: '' },
      }),
      financialAmountField(
        'sellAmount',
        '',
        { hideErrorMessages: true, errorMessages: { required: '' } },
        {
          // walletMaximum: optional(smallerThanOrEqualsParam('sellAmount', 'walletBalance')),
          required: requiredIfStateValue('sellAmount'),
        }
      ),
    ],
  })
);

const amountFormValidation = useVuelidate(makeValidation(amountForm.value), { amountForm: amountForm.value });

const buyCurrency = ref('EUR');

const sellCurrency = ref('GBP');

const buyWallet = ref<Wallet>();

const sellWallet = ref<Wallet>();

const rateDirection = ref(AmountType.SELL);

const tradeDetailsSubject = new Subject<TradeDetails>();

let tradeDetailsSubscription: Subscription | undefined;

const peError = ref<ApiError>();

const errorMessage = ref<string>();

const canVerifyLimits = ref<boolean>();

const walletsLoaded = computed(() => {
  return !!(
    requestManager.requestStates[`${buyCurrency.value}WalletLoadState`] !== 'pending' &&
    requestManager.requestStates[`${sellCurrency.value}WalletLoadState`] !== 'pending'
  );
});

const isTrade = computed(() => sellCurrency.value !== buyCurrency.value);

const unexpectedDetected = computed(
  () =>
    getState(getChildModel(amountForm.value, 'buyAmount')!, 'unexpectedError') ||
    getState(getChildModel(amountForm.value, 'sellAmount')!, 'unexpectedError')
);

const clientId = computed(
  () => onBehalfOfClient.value?.id ?? ahTradesState.store.useAuthStore().loggedInIdentity?.client?.id
);

const currenciesStore = computed(() => {
  if (clientId.value) {
    return !props.timeFrame || !props.timeFrame.isForward
      ? ahTradesState.store.useSettingsStore().getCurrenciesSpot(clientId.value)
      : ahTradesState.store.useSettingsStore().getCurrenciesForward(clientId.value);
  }

  return [];
});

const buyCurrencies = computed(() => {
  let out = currenciesStore.value.map((c) => c.currency);

  if (props.beneficiary) {
    const allowed = props.beneficiary.currency;
    out = out.filter((c) => allowed.includes(c));
  } else if (props.allowedBuyCurrencies) {
    out = out.filter((c) => props.allowedBuyCurrencies?.includes(c));
  }
  return out;
});

const sellCurrencies = computed(() => {
  let out = currenciesStore.value.map((c) => c.currency);
  if (props.allowedSellCurrencies) {
    out = out.filter((c) => props.allowedSellCurrencies?.includes(c));
  }
  return out;
});

const disallowedSellCurrencies = computed(() => {
  if (buyCurrencies.value && buyCurrencies.value.length === 1) {
    return buyCurrencies.value;
  }
  return undefined;
});

const editedTradeDetails = computed<TradeDetails>(() => {
  return {
    walletId: sellWallet.value?.id,
    sellCurrency: sellCurrency.value,
    buyCurrency: buyCurrency.value,
    amount: amount.value,
    amountType: rateDirection.value || undefined,
    timeFrame: props.timeFrame,
    drawdownDate: props.drawdownDate || undefined,
    clientRateMarkup: props.tradeDetails?.clientRateMarkup,
    clientRate: props.tradeDetails?.clientRate,
    valid: walletsLoaded.value && isFormValid.value && (props.drawdownDate?.valid ?? true),
    dirty: props.tradeDetails?.dirty || amountFormValidation.value.$anyDirty,
  } as TradeDetails;
});

const workingTradeDetails = computed<Partial<TradeDetails>>(() => {
  return {
    walletId: sellWallet.value?.id,
    sellCurrency: sellCurrency.value,
    buyCurrency: buyCurrency.value,
    amount: amount.value,
    amountType: rateDirection.value || undefined,
    timeFrame: props.timeFrame,
    drawdownDate: props.drawdownDate || undefined,
  } as Partial<TradeDetails>;
});

const isFormValid = computed(() => {
  if (
    (rateDirection.value === AmountType.SELL && !amountForm.value.sellAmount) ||
    (rateDirection.value === AmountType.BUY && !amountForm.value.buyAmount)
  ) {
    return false;
  }

  if (props.timeFrame?.targetTimeFrame === TimeFrames.OTHER && !props.timeFrame?.targetDate) {
    return false;
  }

  if (clientRateAndMarginForm.value?.isFormInvalid) {
    return false;
  }

  return !amountFormValidation.value.$invalid ?? false;
});

const buyAmountModel = computed(() => getChildModel(amountForm.value, 'buyAmount')!);

const sellAmountModel = computed(() => getChildModel(amountForm.value, 'sellAmount')!);

const amount = computed(() => amountForm.value[inputAmountField.value]);

const inputAmountField = computed(() => (rateDirection.value === AmountType.SELL ? 'sellAmount' : 'buyAmount'));

const calculatedAmountField = computed(() => (rateDirection.value === AmountType.SELL ? 'buyAmount' : 'sellAmount'));

const isPartnerUser = computed(() => !onBehalfOfClient.value && !ahTradesState.store.useAuthStore().isClientUser);

const isVerifyingLimits = computed(() => props.verifyLimits !== false);

const canSeeClientRate = computed(() => ahTradesState.store.useAuthStore().hasAuthorities(AuthorityType.VIEW_SPREADS));

const ownerId = computed(
  () =>
    onBehalfOfClient.value?.id ??
    ahTradesState.store.useAuthStore().loggedInIdentity?.client?.id ??
    ahTradesState.store.useAuthStore().loggedInIdentity?.partner?.id
);

const isClientUser = computed(() => ahTradesState.store.useAuthStore().isClientUser);

const tradingDeskContactHTMLMessage = computed(() => {
  const tradingDeskEmail = ahTradesState.theme.tradingDeskEmail;
  const tradingDeskPhoneNumber = ahTradesState.theme.tradingDeskPhoneNumber;

  if (tradingDeskEmail || tradingDeskPhoneNumber) {
    let out = 'Please contact our trading desk on ';
    if (tradingDeskPhoneNumber) {
      out += getPhoneNumber(tradingDeskPhoneNumber) || tradingDeskPhoneNumber;
      if (tradingDeskEmail) {
        out += ' or ';
      }
    }
    if (tradingDeskEmail) {
      out += `<a target="_blank" href="mailto:${tradingDeskEmail}">${tradingDeskEmail}</a>`;
    }
    out += '.';

    return out;
  }
  return '';
});

const showRequiredError = computed(() => {
  return (
    amountFormValidation.value.$anyDirty &&
    (amountFormValidation.value.buyAmount?.required.$invalid ||
      amountFormValidation.value.sellAmount?.required.$invalid)
  );
});

function setErrorInField(error: string) {
  batchSetState(amountForm.value, 'errors', {
    [calculatedAmountField.value]: [],
    [inputAmountField.value]: [
      {
        name: error,
        html: true,
        error: '',
      },
    ],
  });

  batchSetState(amountForm.value, 'placeholder', {
    [calculatedAmountField.value]: '',
    [inputAmountField.value]: '',
  });
}

function checkPricingEngineValidation(error: ApiError) {
  const options = {
    sellCurrency: sellCurrency.value,
    buyCurrency: buyCurrency.value,
    tradingDeskContactHTMLMessage: tradingDeskContactHTMLMessage.value,
    isClientUser: isClientUser.value,
  } as PricingEngineErrorOptions;

  const content = pricingEngineError(error, options);

  if (content) {
    errorMessage.value = content;
    setErrorInField('peError');
  }
}

function refreshData() {
  loadTradePrice(editedTradeDetails.value).subscribe();
}

function touchForms() {
  submitForm(amountFormValidation.value.amountForm);
  if (onBehalfOfClient.value) {
    clientRateAndMarginForm.value?.touch();
  }
}

function cleanFields() {
  errorMessage.value = undefined;
  peError.value = undefined;
  batchSetState(amountForm.value, 'errors', [calculatedAmountField.value, inputAmountField.value], []);
  batchSetState(amountForm.value, 'unexpectedError', {
    [calculatedAmountField.value]: false,
    [inputAmountField.value]: false,
  });
  batchSetState(amountForm.value, 'placeholder', {
    [calculatedAmountField.value]: '',
    [inputAmountField.value]: '',
  });
}

/**
 * loadTradePrice loads prices for tradeDetails AND handles responses in a tap() pipe
 *
 * This is so it can be called both immediately (on refresh) and in a debounced fashion (on input)
 */
function loadTradePrice(tradeDetails: TradeDetails) {
  if (!tradeDetails.timeFrame || (tradeDetails.timeFrame.isForward && !tradeDetails.drawdownDate?.valid)) {
    return of({});
  }
  cleanFields();

  return requestManager.cancelAndNew(
    'getPrices',
    pricingRequest(
      tradeDetails,
      onBehalfOfClient.value?.id ?? ahTradesState.store.useAuthStore().loggedInIdentity!.client!.id,
      ahTradesState.services.pricingEngine,
      {
        options: {
          errors: {
            silent: true,
          },
        },
      }
    ).pipe(
      tap((price) => {
        // we update the amount for the currency we did not set in our price request
        const amount =
          price.type !== price.response?.ccy1.amountType
            ? price.response?.ccy1.clientAmount
            : price.response?.ccy2.clientAmount;
        amountForm.value[rateDirection.value === AmountType.SELL ? 'buyAmount' : 'sellAmount'] = amount;

        if (canVerifyLimits.value) {
          loadLimits(price, tradeDetails);
        } else {
          emit('update:tradePrice', price);
        }
      }),
      catchError((e) => {
        if (e.response.status === 500) {
          batchSetState(amountForm.value, 'unexpectedError', {
            [calculatedAmountField.value]: true,
            [inputAmountField.value]: false,
          });
          ahTradesState.toast.error('An error occured while calculating trade price. Please try again later.');
        }
        checkPricingEngineValidation(e.response.data);
        emit('update:tradePrice', null);
        return empty();
      })
    )
  );
}

function loadLimits(price: QuotePriceRequest, tradeDetails: TradeDetails) {
  const buyCcy = price.response?.ccy1.amountType === AmountType.BUY ? price.response?.ccy1 : price.response?.ccy2;

  const sellCcy = price.response?.ccy1.amountType === AmountType.SELL ? price.response?.ccy1 : price.response?.ccy2;

  const limitsPriceResponse = {
    amountType: tradeDetails.amountType,
    buyCurrency: buyCcy?.currency,
    sellCurrency: sellCcy?.currency,
    hedgingProduct: price.instrument,
    sellAmount: sellCcy?.clientAmount,
    buyAmount: buyCcy?.clientAmount,
  };

  requestManager
    .cancelAndNew(
      'getLimits',

      ahTradesState.services.limits.verifyLimits(
        onBehalfOfClient.value?.id ?? ahTradesState.store.useAuthStore().loggedInIdentity!.client!.id,
        limitsPriceResponse,
        { errors: { silent: true } }
      )
    )
    .subscribe(
      (limits) => {
        emit('update:walletBalanceUsageRequired', limits.walletBalanceUsageRequired);
        emit('update:tradePrice', price);
      },
      (e) => {
        emit('update:tradePrice', null);
        if (e.response?.status === 422 && e.response?.data.code === LimitsErrorCodes.INVALID_AMOUNT) {
          setErrorInField('limitsError');
          if (price.instrument == HedgingInstruments.FX_SPOT) {
            errorMessage.value = `We regret to inform you that the trade cannot be executed at this time due to the following potential reasons:<ul><br/><li>Your wallet balance is insufficient to proceed with the trade.</li><br /><li>The notional amount surpasses the client limit for ${formatHedgingInstrument(
              price.instrument
            )} trades.</li></ul>For further assistance, please contact us at <a target="_blank" href="mailto:sales@alt21.com">sales@alt21.com</a>.`;
          } else {
            errorMessage.value = `The notional amount exceeds the client limit for ${formatHedgingInstrument(
              price.instrument
            )} set in the limits profile. Please contact Admin or increase the limit for  ${formatHedgingInstrument(
              price.instrument
            )} in the limits profile. ${tradingDeskContactHTMLMessage.value}`;
          }

          return;
        }

        if (e.response?.status === 400 || e.response?.status === 422) {
          ahTradesState.toast.info(e.response.data.message);
        } else {
          ahTradesState.toast.error('An unexpected problem has occurred. Please try again later.');
        }
        batchSetState(amountForm.value, 'unexpectedError', {
          [calculatedAmountField.value]: true,
          [inputAmountField.value]: false,
        });
      }
    );
}

function updateFieldPlaceholders(set: boolean) {
  if (set) {
    batchSetState(amountForm.value, 'placeholder', {
      [calculatedAmountField.value]:
        !!amountForm.value[inputAmountField.value] && props.timeFrame ? 'Calculating...' : '',
      [inputAmountField.value]: '',
    });
  } else {
    batchSetState(amountForm.value, 'placeholder', [calculatedAmountField.value, inputAmountField.value], '');
  }
}

function onBuyCurrencyChanged(value: string) {
  if (sellCurrency.value === value) {
    sellCurrency.value = buyCurrency.value;
  }
  buyCurrency.value = value;
}

function onSellCurrencyChanged(value: string) {
  if (buyCurrency.value === value) {
    buyCurrency.value = sellCurrency.value;
  }
  sellCurrency.value = value;
}

function onInputChange(markupInfo: Partial<TradeDetails> = {}) {
  cleanFields();
  if (isTrade.value) {
    batchSetState(amountForm.value, 'required', {
      [calculatedAmountField.value]: !amountForm.value[inputAmountField.value],
      [inputAmountField.value]: true,
    });
    amountForm.value[calculatedAmountField.value] = '';
    if (props.timeFrame) {
      emit('update:loadingPrices', !!amountForm.value[inputAmountField.value]);
    }
    updateFieldPlaceholders(!props.tradeDetails?.valid);
    if (markupInfo.clientRateMarkup !== undefined || markupInfo.clientRate !== undefined) {
      editedTradeDetails.value.clientRateMarkup = undefined;
      editedTradeDetails.value.clientRate = undefined;
    }
    tradeDetailsSubject.next({ ...editedTradeDetails.value, ...markupInfo });
  } else {
    amountForm.value[calculatedAmountField.value] = amountForm.value[inputAmountField.value];
  }
  emit('update:tradeDetails', { ...editedTradeDetails.value, ...markupInfo });
}

function loadWallet(direction: 'buy' | 'sell') {
  const wallet = direction === 'buy' ? buyWallet.value : sellWallet.value;
  const currency = direction === 'buy' ? buyCurrency.value : sellCurrency.value;

  if (!wallet || currency !== wallet.currency) {
    requestManager
      .newPromise(
        `${currency}WalletLoadState`,
        ahTradesState.store.useWalletsStore().loadCurrencyWallet({
          currency,
          force: true,
          owner: { isPartner: isPartnerUser.value, id: ownerId.value },
        })
      )
      .then((loadedWallet) => {
        (direction === 'buy' ? buyWallet : sellWallet).value = loadedWallet || undefined;
        onInputChange();
      });
  }
}

function onBuyAmountChanged(event: FormEvent) {
  if (event.event === 'form-field-set-value') {
    rateDirection.value = AmountType.BUY;
  }
}

function onSellAmountChanged(event: FormEvent) {
  if (event.event === 'form-field-set-value') {
    rateDirection.value = AmountType.SELL;
  }
}

watch(
  buyCurrencies,
  () => {
    if (buyCurrencies.value?.length) {
      const currBuyCurrency = buyCurrency.value;
      if (!buyCurrencies.value.includes(currBuyCurrency)) {
        buyCurrency.value = buyCurrencies.value[0];
        if (sellCurrency.value === buyCurrency.value) {
          sellCurrency.value = currBuyCurrency;
        }
      }
    }
  },
  { immediate: true }
);

watch(
  sellCurrencies,
  () => {
    if (sellCurrencies.value?.length) {
      const currSellCurrency = sellCurrency.value;
      if (!sellCurrencies.value.includes(currSellCurrency)) {
        sellCurrency.value = sellCurrencies.value[0];
        if (sellCurrency.value === buyCurrency.value) {
          buyCurrency.value = currSellCurrency;
        }
      }
    }
  },
  { immediate: true }
);

watch([() => requestManager.requestStates.getPrices, () => requestManager.requestStates.getLimits], () => {
  emit(
    'update:loadingPrices',
    requestManager.requestStates.getPrices === 'pending' || requestManager.requestStates.getLimits === 'pending'
  );
});

watch(
  () => props.tradeDetails,
  () => {
    if (props.tradeDetails) {
      sellCurrency.value = props.tradeDetails.sellCurrency;
      buyCurrency.value = props.tradeDetails.buyCurrency;
      rateDirection.value = props.tradeDetails.amountType || AmountType.SELL;
      amountForm.value[inputAmountField.value] = props.tradeDetails.amount;
      updateFieldPlaceholders(props.tradeDetails.valid);
    }
  }
);

watch(
  workingTradeDetails,
  (newVal, oldVal) => {
    if (!isEqual(newVal, oldVal)) {
      onInputChange();

      if (newVal?.sellCurrency != oldVal?.sellCurrency || newVal?.buyCurrency != oldVal?.buyCurrency) {
        emit('update:tradePrice', null);
      }
    }
  },
  { immediate: true, deep: true }
);

watch(
  [buyCurrency, sellCurrency, onBehalfOfClient],
  () => {
    if (!buyWallet.value || buyCurrency.value !== buyWallet.value.currency) {
      loadWallet('buy');
    }
    if (!sellWallet.value || sellCurrency.value !== sellWallet.value.currency) {
      loadWallet('sell');
    }
  },
  { immediate: true }
);

watch(
  () => requestManager.requestStates.getPrices,
  () => {
    const pending = requestManager.requestStates.getPrices === 'pending';
    updateFieldPlaceholders(pending);
    clientRateAndMarginForm.value?.calculatingFields(pending);
    if (pending) {
      amountForm.value[calculatedAmountField.value] = '';
    }
  },
  { immediate: true }
);

watch(showRequiredError, () => {
  if (showRequiredError.value) {
    amountFormValidation.value.$touch();
  }
});

onBeforeMount(() => {
  ahTradesState.store.useSettingsStore().loadTradeableCurrencies();
  ahTradesState.store.useSettingsStore().loadTradeableCurrenciesForSpot({ oboClientId: onBehalfOfClient.value?.id });
  ahTradesState.store.useSettingsStore().loadTradeableCurrenciesForForward({ oboClientId: onBehalfOfClient.value?.id });

  tradeDetailsSubscription = tradeDetailsSubject
    .pipe(
      tap(() => requestManager.cancel('getPrices')),
      debounceTime(700),
      filter(() => walletsLoaded.value && amount.value && isTrade.value && !!props.timeFrame),
      switchMap((tradeDetails) => loadTradePrice(tradeDetails))
    )
    .subscribe();

  if (isVerifyingLimits.value) {
    const payload = {
      featureFlag: FeatureFlag.ALLOW_SPOT_TRADING_WITH_WALLET_BALANCE,
      oboClientId: onBehalfOfClient.value?.id,
      force: true,
    };

    ahTradesState.store
      .useFeatureFlagStore()
      .loadFeatureFlag(payload)
      .then(
        (featureFlagResponse) => (canVerifyLimits.value = featureFlagResponse?.treatment === FeatureFlagTreatment.ON)
      );
  }
});

onBeforeUnmount(() => {
  tradeDetailsSubscription!.unsubscribe();
});

defineExpose({
  touchForms,
  refreshData,
  checkPricingEngineValidation,
});
</script>

<template>
  <div class="trade-details-form">
    <!-- sell fields -->
    <div class="row">
      <div class="sell-field col">
        <div class="row">
          <div class="col">
            {{ beneficiary ? 'You are sending:' : "You're selling:" }}
            <span v-if="!beneficiary">
              <InfoTooltip :text="$ahTradesState.i18n.t('tooltips.youAreSelling')" />
            </span>
          </div>
        </div>
        <div class="row">
          <!-- sell currency -->
          <div class="col-5">
            <CurrencySelectInput
              :currency="sellCurrency"
              @update:currency="onSellCurrencyChanged"
              :disallowedCurrencies="disallowedSellCurrencies"
              :allowedCurrencies="sellCurrencies"
            />
          </div>

          <!-- sell amount -->
          <div class="col-7">
            <FormField
              :field="amountFormValidation.amountForm.sellAmount"
              :model="sellAmountModel"
              @form-event="onSellAmountChanged"
            />
          </div>
        </div>
        <div class="row">
          <FieldWalletInfoOrErrors
            class="col-12 field-group"
            :loading="!walletsLoaded"
            :field="amountFormValidation.amountForm.sellAmount"
            :amountToWithdraw="amountForm.sellAmount"
            :model="sellAmountModel"
            :walletCurrency="sellCurrency"
            hideOverdraft
          />
        </div>
      </div>
      <div class="buy-field col">
        <!-- buy fields -->
        <div class="row">
          <div class="col">
            {{ beneficiary ? 'They are receiving:' : "You're buying:" }}
            <span v-if="!beneficiary">
              <InfoTooltip :text="$ahTradesState.i18n.t('tooltips.youAreBuying')" />
            </span>
          </div>
        </div>
        <div class="row">
          <!-- buy currency -->
          <div class="col-5">
            <CurrencySelectInput
              :currency="buyCurrency"
              :allowedCurrencies="buyCurrencies"
              @update:currency="onBuyCurrencyChanged"
            />
          </div>

          <!-- buy amount -->
          <div class="col-7">
            <FormField
              :field="amountFormValidation.amountForm.buyAmount"
              :model="buyAmountModel"
              @form-event="onBuyAmountChanged"
            >
              <template
                v-if="!unexpectedDetected && isTrade && rateDirection === AmountType.BUY"
                #amountForm.buyAmount:prepend
              >
                <BInputGroupText>
                  <IconLock />
                </BInputGroupText>
              </template>
            </FormField>
          </div>
        </div>

        <div class="row" v-if="isTrade">
          <FieldWalletInfoOrErrors
            class="col-12 field-group"
            :field="amountFormValidation.amountForm.buyAmount"
            :model="buyAmountModel"
            :walletCurrency="buyCurrency"
            hideOverdraft
          />
        </div>
      </div>
    </div>
    <div class="row field-group" v-if="showRequiredError">
      <div class="col field-group-errors">
        Must define either the buy or sell amounts.<br />Corresponding amount is automatically calculated.
      </div>
    </div>

    <div class="row field-group mb-4" v-if="errorMessage">
      <div class="col field-group-errors">
        <BoxGrid class="p-0">
          <BoxGridItem cols="1">
            <IconAlertCircle class="icon" />
          </BoxGridItem>
          <BoxGridItem cols="11" class="px-md-0 px-sm-2">
            <div v-html="errorMessage"></div>
          </BoxGridItem>
        </BoxGrid>
      </div>
    </div>

    <TradePriceExchangeRate
      :tradePriceResponse="tradePrice.response"
      class="mt-4"
      v-if="tradePrice && tradePrice.response && rateDirection && isTrade && !onBehalfOfClient"
    />
    <ClientRateAndMarginForm
      v-if="onBehalfOfClient && canSeeClientRate"
      ref="clientRateAndMarginForm"
      @set-markup="onInputChange"
      :tradeDetails="tradeDetails"
      :beneficiary="beneficiary"
      :tradePrice="tradePrice"
      :peError="peError"
    />
  </div>
</template>

<style lang="scss" scoped>
.trade-direction {
  background: getColorHSLA($color-primary, 60%, 0.4);
  height: 1.5em;
  width: 1.5em;
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  line-height: 1.5em;

  svg {
    width: 70%;
    height: 70%;
    margin: 15%;
    display: block;
  }
}

.sell-field,
.buy-field {
  min-width: 220px;
}

.icon {
  font-size: 1.6em;
  @include themedTextColor($color-danger);
}
</style>
