<script setup lang="ts">
import { ref, computed, onMounted, watch, onBeforeUnmount } from 'vue';
import {
  onboardingStatuses,
  ClientFileCategories,
  ComplianceFilesStatus,
  ComplianceStatus,
  Individual,
} from 'ah-api-gateways';
import AppBanner from '@/app/components/common/AppBanner.vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useAuthStore } from '@/app/store/authStore';
import { useIndividualSettingsStore } from '@/app/store/individualSettingsModule';
import { MINUTE } from 'ah-common-lib/src/constants/time';
import { getServices } from '@/app/services';

const authStore = useAuthStore();
const services = getServices();
const individualSettingsStore = useIndividualSettingsStore();
const requestManager = useRequestManager().manager;

const clientOwner = ref<Individual>();
const loadComplianceInterval = ref<number>();

const individualClientDocs = [
  ClientFileCategories.PROOF_OF_ADDRESS,
  ClientFileCategories.PHOTO_ID,
  ClientFileCategories.PROOF_OF_FUNDS,
];

const companyDocs = [
  ClientFileCategories.INCORPORATED_DOCUMENT,
  ClientFileCategories.SAMPLE_INVOICE,
  ClientFileCategories.FINANCIAL_STATEMENT,
];

const companyClientDocs = [...companyDocs, ClientFileCategories.PHOTO_ID, ClientFileCategories.PROOF_OF_ADDRESS];

const documentsNeedUploading = computed(() => {
  if (requestManager.anyPending) {
    return false;
  }
  if (authStore.cachedComplianceCase.item) {
    if (authStore.cachedComplianceCase.item.documentUploadStatus === ComplianceFilesStatus.UPLOADED) {
      return false;
    }
  }
  const docList = isIndividualClient.value ? individualClientDocs : companyClientDocs;
  return docList.filter((i) => !individualSettingsStore.getClientDocument(i)).length > 0;
});

const uboDocumentsNeedUploading = computed(() => {
  if (requestManager.anyPending || !ubos.value) {
    return false;
  }
  return !!ubos.value.find((ubo) => ubo.documentUploadStatus !== ComplianceFilesStatus.UPLOADED);
});

const isUserSignatory = computed(() => isIndividualClient.value || authStore.userData?.individual?.owner);

const isIndividualClient = computed(() => authStore.isClientUser && !authStore.isCompanyClient);

const areTermsAndConditionsRequired = computed(
  () => authStore.complianceStatus === ComplianceStatus.TERMS_AND_CONDITIONS
);

const areTermsAndConditionsSuspended = computed(
  () => authStore.complianceStatus === ComplianceStatus.SUSPENDED_UPDATED_TCS_NOT_ACCEPTED
);

const isClientInOnboarding = computed(
  () => authStore.complianceStatus && onboardingStatuses.includes(authStore.complianceStatus)
);

const ubos = computed(() => individualSettingsStore.ubos);

const clientWithUbos = computed(() => ubos.value && ubos.value.length > 0);

const clientMessage = `Your application has been submitted. In line with our regulatory obligation, our team may be in touch with you to request that you supply additional documents in support of your application. Simply log back into your account, navigate to the 'Settings' section and update your documents accordingly.`;

onMounted(() => {
  authStore.loadComplianceCase();
  loadComplianceInterval.value = window.setInterval(() => {
    if (authStore.isLoggedIn) {
      authStore.loadComplianceCase({ force: true });
    }
  }, MINUTE * 15);
});

onBeforeUnmount(() => {
  if (loadComplianceInterval.value !== null) {
    window.clearInterval(loadComplianceInterval.value);
  }
});

watch(
  () => authStore.isLoggedIn,
  (loggedIn) => {
    if (loggedIn && authStore.isClientUser && isClientInOnboarding.value) {
      requestManager
        .newPromise(
          'loadDeps',
          Promise.all([
            services.individual
              .listIndividuals({
                clientId: authStore.userData?.individual?.client?.id,
                isOwner: true,
              })
              .toPromise(),
            individualSettingsStore.loadClient(true),
            individualSettingsStore.loadClientDocuments(true),
            individualSettingsStore.loadClientUbos(true),
            authStore.loadComplianceCase(),
          ])
        )
        .then((responses) => {
          if (!clientWithUbos.value) {
            companyDocs.push(ClientFileCategories.RESOLUTION_LETTER);
          }
          if (responses[0].list.length === 1) {
            clientOwner.value = responses[0].list[0];
          }
        });
    }
  },
  { immediate: true }
);
</script>

<template>
  <AppBanner v-if="isClientInOnboarding" class="danger text-center">
    <template v-if="areTermsAndConditionsRequired">
      <p>
        <b>Acceptance of Updated T&Cs outstanding</b><br />
        <span v-if="isUserSignatory">
          In order to proceed, please review the
          <RouterLink to="/terms-and-conditions" class="plain-color-link">terms and conditions</RouterLink> of business,
          and authorise in your capacity as a duly authorised person.
        </span>
        <span v-else>
          In order to proceed,
          {{ clientOwner ? `${clientOwner.firstName} ${clientOwner.lastName}` : 'the authorised signatory' }} must
          review and authorise the updated terms and conditions of business.
        </span>
      </p>
    </template>
    <template v-if="areTermsAndConditionsSuspended">
      <p>
        <b>Account Suspended due to Updated T&C's outstanding</b><br />
        <span v-if="isUserSignatory">
          Your account is currently suspended, in order to proceed review
          <RouterLink to="/terms-and-conditions" class="plain-color-link">terms and conditions</RouterLink> of business.
        </span>
        <span v-else>
          In order to proceed,
          {{ clientOwner ? `${clientOwner.firstName} ${clientOwner.lastName}` : 'the authorized signatory' }} must
          review and authorise the updated terms and conditions of business.
        </span>
      </p>
    </template>
    <p v-else class="mb-0">Your account is pending approval. Transactional features are currently disabled.</p>
    <template v-if="documentsNeedUploading || uboDocumentsNeedUploading">
      <p class="mb-0">
        {{ clientMessage }}
      </p>
    </template>
  </AppBanner>
</template>
