<script setup lang="ts">
import { VRow } from 'ah-common-lib/src/common/components';
import { VCol } from 'ah-common-lib/src/common/components';
import SpotLimitsEditor from './SpotLimitsEditor.vue';
import ForwardAndOptionLimitsEditor from './ForwardAndOptionLimitsEditor.vue';
import VariationMarginEditor from './VariationMarginEditor.vue';
import { computed, ref, PropType } from 'vue';
import { ClientRiskLimitsProfile } from 'ah-api-gateways';
import { BModal } from 'bootstrap-vue';
import { VButton } from 'ah-common-lib/src/common/components';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useAhTradesState } from '../../..';
import { useToast } from 'ah-common-lib/src/toast';
import { combineLatest } from 'rxjs';

const props = defineProps({
  clientId: {
    type: String,
    required: true,
  },
  limitsProfileData: {
    type: Object as PropType<ClientRiskLimitsProfile>,
    required: true,
  },
  variationMarginPercentage: {
    type: Number,
    required: true,
  },
});

const requestManager = useRequestManager().manager;

const tradeState = useAhTradesState();

const toast = useToast();

const isCreditFacilityModalShown = ref<boolean>(false);

const isCreditFacilityReviewAndConfirmModalShown = ref<boolean>(false);

const isSpotLimitsFormInvalid = ref<boolean>(false);

const isForwardAndOptionLimitsFormInvalid = ref<boolean>(false);

const isAnyFormInvalid = computed(() => isSpotLimitsFormInvalid.value || isForwardAndOptionLimitsFormInvalid.value);

const currency = computed(() => props.limitsProfileData.currency);

const parsedVariationMarginPercentage = computed(() =>
  (creditFacilityValues.value.variationMarginPercentage * 100).toFixed(2)
);

const creditFacilityValues = ref({
  spotLimitsAmount: props.limitsProfileData.notionalLimitSpots,
  forwardAndOptionsLimitsAmount: props.limitsProfileData.notionalLimitForwardsOptions,
  variationMarginPercentage: props.variationMarginPercentage,
});

function showCreditFacilityModal() {
  isCreditFacilityModalShown.value = true;
}

function showCreditFacilityReviewAndConfirmModal() {
  isCreditFacilityReviewAndConfirmModalShown.value = true;
}

function cancel() {
  creditFacilityValues.value = {
    spotLimitsAmount: props.limitsProfileData.notionalLimitSpots,
    forwardAndOptionsLimitsAmount: props.limitsProfileData.notionalLimitForwardsOptions,
    variationMarginPercentage: props.variationMarginPercentage,
  };

  isCreditFacilityModalShown.value = false;
  isCreditFacilityReviewAndConfirmModalShown.value = false;
}

function onConfirmCreditFacility() {
  const payload = {
    forwardLimit: creditFacilityValues.value.forwardAndOptionsLimitsAmount,
    spotLimit: creditFacilityValues.value.spotLimitsAmount,
    variationMarginPercentage: creditFacilityValues.value.variationMarginPercentage,
  };
  requestManager
    .currentOrNew(
      'createCreditFacilityRequest',
      combineLatest([
        tradeState.services.credit.createCreditFacilityRequest(props.clientId, payload),
        tradeState.services.limits.notifyUsLimit(),
      ])
    )
    .subscribe(() => {
      isCreditFacilityReviewAndConfirmModalShown.value = false;
      toast.success(
        'Thank you. Your request has been successfully received, and you will be notified when it has been processed.'
      );
    });
}
</script>

<template>
  <span>
    <BModal
      v-if="limitsProfileData"
      v-model="isCreditFacilityModalShown"
      centered
      size="lg"
      no-stacking
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #modal-header>
        <div class="modal-header">
          <h5 class="modal-title">Request a New Credit Facility</h5>
          <button type="button" class="close" @click="cancel">
            <span aria-hidden="true" class="close-icon">&times;</span>
          </button>
        </div>
      </template>
      <div class="px-5">
        <VRow class="mb-4">
          <VCol> Please indicate the trading and credit limits you would like to request. </VCol>
        </VRow>
        <SpotLimitsEditor
          :currency="currency"
          :currentLimit="limitsProfileData.notionalLimitSpots"
          :requestedSpotLimitsAmount.sync="creditFacilityValues.spotLimitsAmount"
          :utilisedLimit="limitsProfileData.notionalLimitSpotsInUse"
          :validation.sync="isSpotLimitsFormInvalid"
        />
        <ForwardAndOptionLimitsEditor
          :currency="currency"
          :currentLimit="limitsProfileData.notionalLimitForwardsOptions"
          :requestedForwardAndOptionLimitsAmount.sync="creditFacilityValues.forwardAndOptionsLimitsAmount"
          :utilisedLimit="limitsProfileData.notionalLimitForwardsOptionsInUse"
          :validation.sync="isForwardAndOptionLimitsFormInvalid"
        />
        <VariationMarginEditor :variationMarginPercentage.sync="creditFacilityValues.variationMarginPercentage" />
      </div>
      <template #modal-footer>
        <div class="form-actions">
          <VButton class="btn-secondary mr-2" @click="cancel">Cancel</VButton>
          <VButton class="btn-success" :disabled="isAnyFormInvalid" @click="showCreditFacilityReviewAndConfirmModal"
            >Confirm</VButton
          >
        </div>
      </template>
    </BModal>
    <BModal v-model="isCreditFacilityReviewAndConfirmModalShown" centered no-close-on-backdrop no-close-on-esc>
      <template #modal-header>
        <div class="modal-header">
          <h5 class="modal-title">Review & Confirm</h5>
          <button type="button" class="close" :disabled="requestManager.anyPending" @click="cancel">
            <span aria-hidden="true" class="close-icon">&times;</span>
          </button>
        </div>
      </template>
      <VRow class="px-3">
        <VCol cols="12" class="mb-3">You are <strong>requesting</strong>:</VCol>
        <VCol cols="12">
          <ul class="review-and-confirm-list">
            <li>
              <strong>Spot Limits</strong>: {{ formatCurrencyValue(creditFacilityValues.spotLimitsAmount) }}
              {{ props.limitsProfileData.currency }}
            </li>
            <li>
              <strong>Forward and Option Limits</strong>:
              {{ formatCurrencyValue(creditFacilityValues.forwardAndOptionsLimitsAmount) }}
              {{ props.limitsProfileData.currency }}
            </li>
            <li><strong>Variation Margin</strong>: {{ parsedVariationMarginPercentage }}%</li>
          </ul>
        </VCol>
      </VRow>
      <template #modal-footer>
        <div class="form-actions">
          <VButton class="btn-secondary mr-2" :disabled="requestManager.anyPending" @click="cancel">Cancel</VButton>
          <VButton class="btn-success" :loading="requestManager.anyPending" @click="onConfirmCreditFacility"
            >Confirm</VButton
          >
        </div>
      </template>
    </BModal>
    <slot v-bind="{ showCreditFacilityModal }"></slot>
  </span>
</template>

<style scoped>
.modal-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .close-icon {
    font-size: 28px;
  }
}

.review-and-confirm-list {
  li {
    margin-bottom: 1rem;
  }
}
</style>
