<script setup lang="ts">
import { computed, onBeforeMount, reactive, ref, watch } from 'vue';
import InfoBlock, { InformationBlock } from 'ah-common-lib/src/common/components/InfoBlock.vue';
import { FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { makeTradingDetailsFM } from './reviewForms';
import { setState, toDataModel, updateModel, getChildModel, resetForm } from 'ah-common-lib/src/form/helpers';
import { AuthorityType, CompanyQuestionnaire, IndividualQuestionnaire, PublicComplianceCase } from 'ah-api-gateways';
import {
  expectedVolumeToHuman,
  occupationLabels,
  sourceOfFundsLabels,
  sourceOfFundOptions,
  tradeFrequencyToHuman,
  occupationOptions,
  sourceOfFundsToHuman,
} from 'ah-common-lib/src/helpers/questionnaire';
import { getServices } from '@/app/services';
import { useAuthStore } from '@/app/store/authStore';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { countryNameFromCC } from 'ah-common-lib/src/helpers/countries';
import { catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { waitForCQRSEntityChange } from 'ah-requests';
import { useSettingsStore } from '@/app/store/settingsModule';
import { tradingDetailsFormToModel } from '@/app/helpers/registration/forms';
import { useToast } from 'ah-common-lib/src/toast';

const requestManager = useRequestManager().manager;

const services = getServices();

const authStore = useAuthStore();

const settingsStore = useSettingsStore();

const toast = useToast();

const props = withDefaults(
  defineProps<{ model: PublicComplianceCase; editable?: boolean; permissions?: AuthorityType[] }>(),
  { editable: true, permissions: () => [] }
);

const emit = defineEmits<{ (e: 'update:model', value: PublicComplianceCase): void }>();

const state = reactive<{ editing: boolean }>({ editing: false });

const questionnaireForm = reactive<FormDefinition>({
  form: makeTradingDetailsFM(),
  validation: null,
});

onBeforeMount(() => {
  settingsStore.loadTradeableCurrencies().then(() => {
    const currencySelect = getChildModel(questionnaireForm.form!, 'reportingCurrency')!;
    setState(currencySelect, 'options', settingsStore.currenciesAsOptions(false));
  });
});
const isEditable = computed(() => props.editable !== false);

const fxInterests = computed(() => {
  const out = [];
  if (props.model.questionnaire.fxSpotInterest) {
    out.push('Spots');
  }
  if (props.model.questionnaire.fxForwardInterest) {
    out.push('Forwards');
  }
  return out;
});

const sourceOfFunds = computed(() =>
  sourceOfFundsToHuman((props.model.questionnaire as CompanyQuestionnaire).sourceOfFunds)
);

const volume = computed(() => expectedVolumeToHuman(props.model.questionnaire.maxExpectedVolume));

const frequency = computed(() => tradeFrequencyToHuman(props.model.questionnaire.tradeFrequency));

const payOBOAllowed = computed(() => (props.permissions.includes(AuthorityType.PAY_ON_BEHALF_OF) ? 'On' : 'Off'));

const tradeOBOAllowed = computed(() => (props.permissions.includes(AuthorityType.TRADE_ON_BEHALF_OF) ? 'On' : 'Off'));

const infoBlock = computed(() => [
  {
    label: 'Please select the Foreign Exchange products that you are interested in',
    key: 'fxInterest',
    value: fxInterests.value,
    cols: 12,
  },
  { label: 'Source of Funds?', key: 'sourceOfFunds', value: sourceOfFunds.value, cols: 4 },
  { label: 'What currencies will you be buying?', key: 'buyCurrencies', cols: 4 },
  { label: 'What currencies will you be selling?', key: 'sellCurrencies', cols: 4 },
  { label: 'Frequency of Activity', key: 'tradeFrequency', value: frequency.value, cols: 4 },
  { label: 'Volumes', key: 'tradeVolume', value: volume.value, cols: 4 },
  {
    label: 'Destination of Payments',
    key: 'beneficiaryCountries',
    value: props.model.questionnaire.beneficiaryCountries.map((cc) => countryNameFromCC(cc)),
    cols: 4,
  },
  { label: 'What is your tax reporting currency?', key: 'reportingCurrency', cols: 4 },
  { label: 'Emir Classification', key: 'emirClassification', cols: 4 },
]);

const permissionsBlock = ref<InformationBlock[]>([
  { label: 'Trading OBO', key: 'tradingAllowed', value: payOBOAllowed.value, cols: 4 },
  { label: 'Payment OBO', key: 'paymentsAllowed', value: tradeOBOAllowed.value, cols: 4 },
]);

const calculatedModel = computed(() => {
  const questionnaire = toDataModel(questionnaireForm.form);

  return {
    ...questionnaire,
    questionnaire: tradingDetailsFormToModel(questionnaireForm.form, true),
  };
});

function save() {
  const clientId = authStore.loggedInIdentity!.client!.id;
  if (calculatedModel.value.questionnaire.maxExpectedVolume === Infinity) {
    calculatedModel.value.questionnaire.maxExpectedVolume = undefined;
  }

  requestManager
    .currentOrNew(
      'saveQuestionnaire',
      services.compliance
        .updateClientComplianceCase(clientId, {
          questionnaire: { ...calculatedModel.value.questionnaire },
        })
        .pipe(
          mergeMap((idEntity) =>
            waitForCQRSEntityChange(idEntity, () => services.compliance.getClientComplianceCase(idEntity.id)).pipe(
              catchError(() => of(props.model))
            )
          )
        )
    )
    .subscribe((complianceCase) => {
      toast?.success('Questionnaire answers changed successfully');
      state.editing = false;
      emit('update:model', complianceCase);
    });
}

watch(
  () => [questionnaireForm.form.sourceOfFundsSelection, questionnaireForm.form.occupationSelection],
  () => {
    const sourceOfFundsField = getChildModel(questionnaireForm.form, 'sourceOfFunds');
    const occupationField = getChildModel(questionnaireForm.form, 'occupation');

    if (sourceOfFundsField) {
      const isOther = questionnaireForm.form.sourceOfFundsSelection === sourceOfFundsLabels.OTHER;
      setState(sourceOfFundsField, 'required', isOther);
      setState(sourceOfFundsField, 'hidden', !isOther);
      if (questionnaireForm.validation?.sourceOfFunds) {
        questionnaireForm.validation.sourceOfFunds.$reset();
      }
    }
    if (occupationField) {
      const isOther = questionnaireForm.form.occupationSelection === occupationLabels.OTHER;
      setState(occupationField, 'required', isOther);
      setState(occupationField, 'hidden', !isOther);
      if (questionnaireForm.validation?.occupation) {
        questionnaireForm.validation.occupation.$reset();
      }
    }
  },
  { immediate: true }
);

watch(
  () => props.model,
  () => {
    resetForms();
  },
  { immediate: true }
);

onBeforeMount(() => {
  settingsStore.loadCountries().then(() => {
    const model = getChildModel(questionnaireForm.form, 'beneficiaryCountries');
    if (model) {
      setState(
        model,
        'options',
        settingsStore.allCountries.map((country) => ({ label: country.name, value: country.cc }))
      );
    }
  });

  settingsStore.loadTradeableCurrencies().then(() => {
    ['sellCurrencies', 'buyCurrencies', 'reportingCurrency'].forEach((field) => {
      const model = getChildModel(questionnaireForm.form, field);
      if (model) {
        setState(model, 'options', settingsStore.currenciesAsOptions(false));
      }
    });
  });
});

function toggleEditing() {
  state.editing = !state.editing;
  if (!state.editing) {
    resetForms(true);
  }
}

function resetForms(resetState = false) {
  updateModel(questionnaireForm.form, props.model.questionnaire ?? {});

  if (props.model.questionnaire?.minExpectedVolume === 10000001) {
    questionnaireForm.form.maxExpectedVolume = Infinity;
  }

  const sourceOfFunds = (props.model.questionnaire as CompanyQuestionnaire)?.sourceOfFunds ?? null;
  if (sourceOfFunds) {
    if (!sourceOfFundOptions.find((o) => o.value === sourceOfFunds)) {
      questionnaireForm.form.sourceOfFundsSelection = sourceOfFundsLabels.OTHER;
    } else {
      questionnaireForm.form.sourceOfFundsSelection = sourceOfFunds;
      questionnaireForm.form.sourceOfFunds = '';
    }
  }

  const occupation = (props.model.questionnaire as IndividualQuestionnaire)?.occupation ?? null;
  if (occupation) {
    if (!occupationOptions.find((o) => o.value === occupation)) {
      questionnaireForm.form.occupationSelection = occupationLabels.OTHER;
    } else {
      questionnaireForm.form.occupationSelection = occupation;
      questionnaireForm.form.occupation = '';
    }
  }
  if (resetState) {
    questionnaireForm.validation && resetForm(questionnaireForm.validation);
  }
}
</script>

<template>
  <div class="card-block" x-test-name="trading-details-review">
    <div class="card-review-header">
      <h2>Trading Details</h2>
      <div class="button-holder" v-if="isEditable">
        <VButton blurOnClick @click="toggleEditing" class="btn-stroked">
          {{ state.editing ? 'Cancel' : 'Edit' }}
        </VButton>
        <VButton
          blurOnClick
          @click="save"
          v-if="state.editing"
          :loading="requestManager.anyPending"
          :disabled="questionnaireForm.validation && questionnaireForm.validation.$invalid"
          class="ml-3"
        >
          Save
        </VButton>
      </div>
    </div>
    <template v-if="!state.editing">
      <InfoBlock emptyValue="-" :model="model.questionnaire" :info="infoBlock" />
      <h3 class="mb-3">OBO Permissions</h3>
      <InfoBlock emptyValue="-" :model="model" :info="permissionsBlock" />
    </template>
    <template v-else>
      <ValidatedForm :fm="questionnaireForm.form" :validation.sync="questionnaireForm.validation">
        <template #tradingFM.beneficiaryCountries:label>
          <div>Destination of Payments&ast;</div>
          <div class="destination-sub-label mb-2">Select up to 5 countries</div>
        </template>
      </ValidatedForm>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.card-review-header {
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;

  h2 {
    font-size: $h3-font-size;
    margin-bottom: 0;
  }

  .button-holder {
    margin-left: auto;
    display: inline-flex;
    .btn {
      min-width: 7rem;
    }
  }
}

.destination-sub-label {
  font-size: $font-size-sm;
  font-weight: $font-weight-regular;
  color: $darkGrey;
}

::v-deep {
  label {
    font-weight: $font-weight-semibold;
    font-size: $font-size-base;
  }
}
h4 {
  font-weight: $font-weight-semibold;
}
</style>
