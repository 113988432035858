import { ApiError } from 'ah-requests';
import { GenericErrorCodes, PricingEngineErrorCodes } from 'ah-api-gateways';

export interface PricingEngineErrorOptions {
  sellCurrency?: string;
  buyCurrency?: string;
  tradingDeskContactHTMLMessage?: string;
  isClientUser?: boolean;
}

// List of Pricing Engine Errors that should show the Partner's contacts
export const PRICING_ENGINE_ERRORS_NEEDING_SUPPORT = [
  PricingEngineErrorCodes.AMOUNT_OUT_OF_BOUNDS,
  PricingEngineErrorCodes.OPTION_PREMIUM_LESS_THAN_THRESHOLD,
  PricingEngineErrorCodes.OPTION_PREMIUM_GREATER_THAN_THRESHOLD,
  PricingEngineErrorCodes.UNAVAILABLE_CURRENCY_PAIR,
  PricingEngineErrorCodes.CLIENT_MARKUP_IS_NEGATIVE,
  PricingEngineErrorCodes.BASIS_POINTS_BELOW_PARTNER_PROFIT_LIMITS,
  PricingEngineErrorCodes.BASIS_POINTS_ABOVE_PARTNER_PROFIT_LIMITS,
  PricingEngineErrorCodes.CLIENT_MARKUP_IS_NEGATIVE,
];

export function pricingEngineError(error: ApiError, options: PricingEngineErrorOptions) {
  let peError: ApiError | null = null;

  if (error.code === GenericErrorCodes.VALIDATION_ERROR && error.subErrors?.length) {
    peError = error.subErrors[0];
  } else if (PRICING_ENGINE_ERRORS_NEEDING_SUPPORT.includes(error.code as PricingEngineErrorCodes)) {
    peError = error;
  }
  if (peError) {
    let content = peError.message;
    if (peError.code === PricingEngineErrorCodes.UNAVAILABLE_CURRENCY_PAIR) {
      content = `The currency pair ${options.sellCurrency}${options.buyCurrency} is not currently tradeable.`;
    }
    if (peError.code === PricingEngineErrorCodes.CLIENT_MARKUP_IS_NEGATIVE && options.isClientUser) {
      content = `Unable to obtain a valid price for your client profile. <br/> ${options.tradingDeskContactHTMLMessage}`;
    } else if (PRICING_ENGINE_ERRORS_NEEDING_SUPPORT.includes(peError.code as PricingEngineErrorCodes)) {
      if (options.tradingDeskContactHTMLMessage) {
        if (!content.endsWith('.')) {
          content += '.';
        }
        content += ` <br/> ${options.tradingDeskContactHTMLMessage}`;
      }
    }
    return content;
  }
  return null;
}
